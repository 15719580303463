
<template>
<form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row gx-lg-4 g-3">
        <div class="col-lg-6">
            <label class="form-label">Allocated between</label>
            <flat-pickr
                v-model="data.created_between"
                :config="rangeConfig"
                placeholder="Select date"
                class="form-control flatpickr-input"
            ></flat-pickr>
        </div>
        <div class="col-lg-6">
            <label class="form-label">Allocated Quantity</label>
            <div class="pt-4">
                <Slider v-model="data.allocated_quantity_between" :merge="10" :max="1000" />
            </div>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>
import flatPickr from "vue-flatpickr-component";
import IsLoading from '@/components/IsLoading.vue'
import Slider from "@vueform/slider";

export default {
    components: {
        IsLoading,
        Slider,
        flatPickr
    },
    data() {
        return {
            isLoading: false,
            data: {
                created_between: "",
                allocated_quantity_between: [0, 500]
            },
            dateConfig:{
                wrap: true, 
                altFormat: 'M j, Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                // enableTime: true,
            },
            rangeConfig: {
                mode: "range",
                dateFormat: "Y-m-d",
                altInput: true,
                showMonths: 2,
                altFormat: "F j, Y",
                // minDate: "today",
                maxDate: new Date().fp_incr(1)
            },
            mirrorData: null,
        }
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
